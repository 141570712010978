/**
 * Scroll to top on route change
 *
 * Based on: https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md
 *
 * Modified to exclude routes with hashes, as those should scroll to the anchor,
 * even though those are most likely to come from external links.
 */

import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.location !== prevProps.location &&
      this.props.location.hash === ''
    ) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

ScrollToTop.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object.isRequired,
};

ScrollToTop.defaultProps = {
  children: undefined,
};

export default withRouter(ScrollToTop);
