/**
 * Saga mode constants
 *
 * DAEMON (default)- stats a saga on component mount and never cancels it or starts again
 * RESTART_ON_REMOUNT - stats a saga when a component is being mounted and cancels with `task.cancel()` on component un-mount for improved performance.
 * ONCE_TILL_UNMOUNT - behaves like `RESTART_ON_REMOUNT` but never runs the saga again.
 *
 * Usage in a container:
 * import { DAEMON } from '../../utils/core/constants'; // Saga mode definition // _platform
 * import injectSaga from '../../utils/injectSaga'; // _platform
 * import saga from './saga';
 *
 * const withSaga = injectSaga({ key: 'app', saga, mode: DAEMON }); // mode is the optional argument
 *
 * export default compose(withSaga)(ContainerName);
 */

export const DAEMON = '@@saga-injector/daemon';
export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';
