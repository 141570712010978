/**
 * Format the token for storage in state
 *
 * @param {object} data Response data from the login and refresh token endpoints
 * @returns currentUser object
 */
export function formatToken(data) {
  const timestamp = Math.floor(new Date().getTime() / 1000);
  const timeDiff = timestamp - data.generated;
  const decodedToken = decodeToken(data.token);
  const permissions = decodedToken
    ? decodedToken['INCREMENTAL.LORI/PERMISSION']
    : null;

  return {
    token: data.token,
    tokenData: {
      generatedServer: data.generated,
      expiryServer: data.expiry,
      generatedLocal: timestamp,
      timeDifference: timeDiff,
      tokenExpiry: data.expiry + timeDiff,
    },
    permissions,
    roles: data.roles,
    userId: decodedToken.nameid,
    disableClaiming: !(
      !decodedToken.DisableClaiming || decodedToken.DisableClaiming === 'False'
    ),
  };
}

/**
 * Store authentication token
 *
 * @param {object} userData User data object as outputted by utils/formatToken
 * @param {boolean} [useSessionStorage=false] Use session or local storage
 */
export function storeToken(userData, useSessionStorage = false) {
  if (!!useSessionStorage) {
    sessionStorage.setItem(
      'auth',
      JSON.stringify({ token: userData.token, tokenData: userData.tokenData })
    );
    localStorage.removeItem('auth');
  } else {
    localStorage.setItem(
      'auth',
      JSON.stringify({ token: userData.token, tokenData: userData.tokenData })
    );
    sessionStorage.removeItem('auth');
  }
}

/**
 * Retrieve authentication token
 *
 * @returns {object OR null} Parsed value from session or local storage, or null
 */
export function retrieveToken() {
  return (
    (sessionStorage.auth && JSON.parse(sessionStorage.auth)) ||
    (localStorage.auth && JSON.parse(localStorage.auth)) ||
    null
  );
}

/**
 * Remove authentication token
 */
export function removeToken() {
  localStorage.removeItem('auth');
  sessionStorage.removeItem('auth');
}

/**
 * Decode token
 */
export function decodeToken(token) {
  if (!token) return false;

  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(window.atob(base64));
}
