/**
 * WebApp selectors
 */
import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Container state
 */
const selectState = state => state.webApp || initialState;

/**
 * Cart
 */
const selectCart = () => createSelector(selectState, state => state.cart);

/**
 * Clients
 */
const selectClients = () => createSelector(selectState, state => state.clients);

/**
 * CustomerReps
 */
const selectCustomerReps = () =>
  createSelector(selectState, state => state.customerReps);

/**
 * Menu definitions
 */
const selectMenu = () => createSelector(selectState, state => state.menu);

const selectRoutes = () => createSelector(selectState, state => state.routes);

/**
 * Site settings
 */
const selectSettings = () =>
  createSelector(selectState, state => state.settings);

// Next Claiming Period (single)
const selectSettingsNextClaimingPeriod = () =>
  createSelector(
    selectSettings(),
    state =>
      state &&
      state.claimingPeriods &&
      state.claimingPeriods.find(item => item.dateEndsUser > new Date())
  );

// Points UOM
const selectSettingsPointsUOM = () =>
  createSelector(
    selectSettings(),
    state =>
      state &&
      state.unitsOfMeasure &&
      state.unitsOfMeasure.find(item => !!item.pointsMeasure)
  );

// Sales UOMs
const selectSettingsSalesUOMs = () =>
  createSelector(
    selectSettings(),
    state =>
      state &&
      state.unitsOfMeasure &&
      state.unitsOfMeasure.filter(item => !item.pointsMeasure)
  );

// Program Settings
const selectSettingsProgram = () =>
  createSelector(selectSettings(), state => state && state.program);

/**
 * Wishlist
 */
const selectWishlist = () =>
  createSelector(selectState, state => state.wishlist);

export {
  selectState,
  selectCart,
  selectClients,
  selectCustomerReps,
  selectMenu,
  selectRoutes,
  selectSettings,
  selectSettingsNextClaimingPeriod,
  selectSettingsPointsUOM,
  selectSettingsProgram,
  selectSettingsSalesUOMs,
  selectWishlist,
};
