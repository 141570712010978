/**
 * Helper for Loadable to dynamically load components and split into webpack chunks
 */

import Loadable from 'react-loadable';
import Loading from '../components/Loading/Loading'; // _platform

const LoadAsync = (loadFn, opts) =>
  Loadable({
    loader: loadFn,
    loading: Loading,
    delay: opts && opts.delay ? opts.delay : 200, // Delay displaying the loader in milliseconds
    timeout: opts && opts.timeout ? opts.timeout : null, // Show timeout message in milliseconds
  });

export default LoadAsync;
