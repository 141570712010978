import React from 'react';
import PropTypes from 'prop-types';
import { Alert, styled } from '@smooth-ui/core-sc';

const StyledAlert = styled(Alert)`
  margin-top: 1rem;
`;

export const Title = styled.div`
  font-weight: bold;
`;

export const Multiline = styled.div`
  white-space: pre-line;
`;

const MessageBox = ({ children, title = undefined, variant = 'primary' }) => {
  return (
    <StyledAlert variant={variant === 'error' ? 'danger' : variant}>
      {title ? <Title>{title}</Title> : null}
      <Multiline>{children}</Multiline>
    </StyledAlert>
  );
};

MessageBox.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  variant: PropTypes.string,
};

MessageBox.defaultProps = {
  title: undefined,
  variant: 'primary',
};

export default MessageBox;
