/**
 * App Actions
 */

import {
  COUNTRIES_REQUEST,
  COUNTRIES_SUCCESS,
  COUNTRIES_ERROR,
  SET_USER,
  USER_LOGOUT_REQUEST,
  USER_TOKEN_REQUEST,
  USER_TOKEN_SUCCESS,
  USER_TOKEN_ERROR,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_ERROR,
} from './constants';

/**
 * Countries request - start the request saga
 *
 * @returns {object} An action object with a type of COUNTRIES_REQUEST
 */
export function countriesRequest() {
  return {
    type: COUNTRIES_REQUEST,
  };
}

/**
 * Dispatched when countriesRequest succeeds by the request saga
 *
 * @param {object} response The response
 * @param {number} lastUpdated Unix timestamp
 * @returns {object}         An action object with a type of COUNTRIES_SUCCESS and the response
 */
export function countriesSuccess(response, lastUpdated) {
  return {
    type: COUNTRIES_SUCCESS,
    response,
    lastUpdated,
  };
}

/**
 * Dispatched when countriesRequests fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of COUNTRIES_ERROR and the error
 */
export function countriesError(error) {
  return {
    type: COUNTRIES_ERROR,
    error,
  };
}

/**
 * Dispatched upon successful user authentication and ensuring they have a valid profile
 *
 * @param {object} details User authentication details
 * @param {string} optons  Object containing additional options such as isProfileValid and/or forcePasswordChange
 * @return {object}        An action object with a type of SET_USER and the user's auth details
 */
export function setUser(details, options) {
  return {
    type: SET_USER,
    details,
    options,
  };
}

/**
 * User logout request - start the request saga
 *
 * @param {object} options Modifier options such as skipRemote (bool) to avoid sending an API request
 * @returns {object} An action object with a type of USER_LOGOUT_REQUEST
 */
export function userLogoutRequest(options) {
  return {
    type: USER_LOGOUT_REQUEST,
    options,
  };
}

/**
 * User profile request - start the request saga
 *
 * @returns {object} An action object with a type of USER_PROFILE_REQUEST
 */
export function userProfileRequest() {
  return {
    type: USER_PROFILE_REQUEST,
  };
}

/**
 * User Profile Success - Dispatched when requestProfile succeeds by the request saga
 *
 * @param {object} response Data from the response
 * @param {number} lastUpdated Unix timestamp
 * @returns {object}        An action object with the type of PROFILE_DETAILS and the response data
 */
export function userProfileSuccess(response, lastUpdated) {
  return {
    type: USER_PROFILE_SUCCESS,
    response,
    lastUpdated,
  };
}

/**
 * Dispatched when userProfileRequest fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of USER_PROFILE_ERROR and the error
 */
export function userProfileError(error) {
  return {
    type: USER_PROFILE_ERROR,
    error,
  };
}

/**
 * User token request - start the request saga
 *
 * @returns {object} An action object with a type of USER_TOKEN_REQUEST
 */
export function userTokenRequest(settingsApp) {
  return {
    type: USER_TOKEN_REQUEST,
    settingsApp,
  };
}

/**
 * Dispatched when userTokenRequest succeeds by the request saga
 *
 * @param {object} response The response
 * @returns {object}         An action object with a type of USER_TOKEN_SUCCESS and the response
 */
export function userTokenSuccess(response) {
  return {
    type: USER_TOKEN_SUCCESS,
    response,
  };
}

/**
 * Dispatched when userTokenRequest fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of USER_TOKEN_ERROR and the error
 */
export function userTokenError(error) {
  return {
    type: USER_TOKEN_ERROR,
    error,
  };
}
