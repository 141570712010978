import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Style, SEO and settings
import { withTheme } from '@smooth-ui/core-sc';

import getLoginPath from './getLoginPath'; // _platform
import {
  selectCurrentUser,
  selectCurrentUserTemp,
} from '../containers/App/selectors'; // _platform

/**
 * Private Route - HoC for react-router Route component
 *
 * TODO: Extend this component to handle granular permissions
 * TODO: Extend with prop to supply function to determine permission `verifyFn` similar to LoadAsync
 *
 * @param {object} { component, user, tempAuthAllowed, ...rest }
 * user prop can be either currentUser or currentUserTemp, depending on what the parent component determines is applicable
 */
class PrivateRoute extends Component {
  render() {
    // Spread the rest this.props in order to avoid passing both `component` and `render` to Route component
    const { component: Component, user, tempAuthAllowed, ...rest } = this.props;
    const { currentUser, currentUserTemp } = this.props;

    // Determine which user object to use
    // 1. Use `user` prop if supplied
    // 2. If `tempAuthAllowed` prop:
    //   1. Is true: use `currentUserTemp` if available, if not use currentUser
    // 3. Is false: use currentUser
    //
    // Check for user.token and currentUserTemp.token to ensure they're not the
    // default empty objects. Cleaner than using Object.keys
    let userObject = {};
    if (user && user.token) {
      userObject = user;
    } else if (tempAuthAllowed && currentUserTemp && currentUserTemp.token) {
      userObject = currentUserTemp;
    } else {
      userObject = currentUser;
    }

    return (
      <Route
        {...rest}
        render={props =>
          !!userObject.token ? (
            <Component
              {...props}
              user={userObject}
              routes={rest.routes}
              menuId={rest.menuId}
            />
          ) : (
            <Redirect push to={getLoginPath(this.props.theme.settingsApp)} />
          )
        }
      />
    );
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentUserTemp: PropTypes.object.isRequired,
  tempAuthAllowed: PropTypes.bool,
  theme: PropTypes.object,
  user: PropTypes.object,
};

PrivateRoute.defaultProps = {
  user: {},
  tempAuthAllowed: false,
  theme: { settingsApp: {} },
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser(),
  currentUserTemp: selectCurrentUserTemp(),
});

const withConnect = connect(
  mapStateToProps,
  null
);

export default compose(
  withTheme,
  withConnect
)(PrivateRoute);
