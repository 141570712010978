import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import createReducer from './reducers';

export default function configureStore(initialState = {}, history) {
  let composeEnhancers = compose;
  let reduxSagaMonitorOptions = {};

  // If Redux DevTools and Saga Dev Tools Extensions are installed, enable them. Otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
    // Redux DevTools
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // shouldHotReload: false,
      });
    }

    // Saga Dev Tools - enable once it supports redux-saga 1.x.x
    if (window.window.__SAGA_MONITOR_EXTENSION__) {
      reduxSagaMonitorOptions = {
        sagaMonitor: window.__SAGA_MONITOR_EXTENSION__,
      };
    }
  }
  /* eslint-enable */

  // Create the middlewares
  // 1. sagaMiddleware - redux-saga
  // 2. logger - redux-logger
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const middlewares = [sagaMiddleware];

  if (process.env.NODE_ENV === 'development') {
    // Add redux-logger in dev environment as it was added as a devDependency
    const { createLogger } = require('redux-logger');

    const logger = createLogger({
      collapsed: true,
      duration: true,
    });

    middlewares.push(logger);
  }

  const enhancers = [applyMiddleware(...middlewares)];

  // Create the store
  const store = createStore(
    createReducer(),
    initialState,
    composeEnhancers(...enhancers)
  );

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Register reducers
  store.injectedSagas = {}; // Register saga

  // Make reducers hot reloadable
  // https://github.com/wesbos/Learn-Redux/blob/master/client/store.js
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return store;
}
