import React from 'react';
import PropTypes from 'prop-types';
import { styled, withTheme } from '@smooth-ui/core-sc';

const SpinLoader = styled.span`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  display: flex;
  border-radius: 50%;
  border: ${props => `${props.thickness}px solid ${props.sColor}`};
  border-top: ${props => `${props.thickness}px solid ${props.pColor}`};
  height: ${props => (props.height ? `${props.height}px` : '50px')};
  width: ${props => (props.width ? `${props.width}px` : '50px')};
  animation: spin 1s linear infinite;
`;

const LoadingAnimation = ({ className, style, theme = {} }) => (
  <SpinLoader
    className={className}
    height={25}
    width={25}
    thickness={2}
    pColor={theme.loadingSpinnerPrimary || '#555'}
    sColor={theme.loadingSpinnerSecondary || '#eee'}
    style={style}
  />
);

LoadingAnimation.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  theme: PropTypes.object,
};

LoadingAnimation.defaultProps = {
  className: undefined,
  style: undefined,
  theme: {},
};

export const LoadingAnimationCentered = props => (
  <LoadingAnimation style={{ margin: '0 auto' }} {...props} />
);

// export default LoadingAnimation;
export default withTheme(LoadingAnimation);
