/**
 *
 * Settings defaults - do not edit this file in individual projects, as it provides fallbacks.
 *
 * You can override specific values in the project src/theme/settingsApp.js file.
 *
 */

const settingsDefault = {
  // How long before the token expiry should the token be refreshed (in minutes)
  authTokenRefreshBeforeExpiry: 10,

  // Input date format
  dateFormat: 'dd/MM/yyyy',
  dateLongFormat: 'LLL dd, yyyy',
  dateTimeFormat: 'dd/MM/yyyy HH:mm',
  dateTimeLongFormat: 'E, LLL dd, yyyy HH:mm',

  // Form prefer optional indicator over required
  formPreferOptionalIndicator: false,
  formIndicatorOptionalText: '(optional)',
  formIndicatorRequiredText: ' *',

  // Path to the login page.
  loginPagePath: '/login/',

  // Toggle Remember Me option for login
  loginRememberMeEnabled: true,

  // Setting this option to true stores the token information in SessionStorage,
  // meaning it's only available to the current tab, and only until the tab is closed.
  // Setting this to true will disable Remember Me.
  loginUseSessionStorage: false,

  // Redirect users with incomplete profiles after signing in.
  // If the path is not specified (or false), the user will stay on the same
  // page where the login form is.
  // The user will be added to currentUserTemp, and any profile update module
  // will need to be supplied that object in order for display content and update
  // the back-end.
  // Important!: If the user is being redirected, ensure that the PrivateRoute
  // component has the `tempAuthAllowed` prop set, otherwise the user will not
  // be able to see/update their profile
  // Relative path with the leading slash. Eg: '/profile/'. Default: false
  redirectOnIncompleteProfile: false,

  // Password reset / Forgot password
  loginPasswordResetLabel: 'Reset Password',
  loginPasswordResetShow: true,
  passwordResetEnabled: true,
  passwordResetPagePath: '/password-reset/',

  // Path to the logout page - without trailing slash as it's not an exact path
  logoutPagePath: '/logout',

  // Path to the profile page
  profilePagePath: '/profile/',

  // Mobile App SSO
  mobileAppSSOEnabled: false,
  mobileAppSSOPagePath: '/sso/',

  // Rewards page - disable category listing and display rewards on the main page
  rewardsCategorySkip: false,

  // Minimum password character length
  minimumPasswordLength: 7,
  passwordRequirementMessage:
    'Passwords must be at least 7 characters long and must contain at least 1 digit and 1 lowercase letter.',

  // Default page size for lists
  defaultPageSize: 100,

  // Maximum state data cache time (seconds) - used by shouldFetchData utility
  // Default 5 minutes for regular data, or 10 minutes for infrequent data (eg, contact form definitions)
  dataCacheTime: 300,
  dataCacheTimeLong: 600,

  // Order status labels
  orderStatusLabels: {
    Received: 'Claim Received',
    Ordered: 'Reward Ordered',
    Packing: 'Reward being Packed',
    Dispatched: 'Reward Dispatched',
    BackOrder: 'Reward has been placed on Back Order',
  },
};

export default settingsDefault;
