/**
 * App
 *
 * This is the main container that gets loaded and is a wrapper around all other components.
 * It should contain containers/components and state available to all other pages.
 *
 * See below for order of import statements
 */

// Core imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Style, SEO and settings
import { withTheme } from '@smooth-ui/core-sc';

// Component features
import { apiSetAuthorizationToken } from '../../utils/request'; // _platform
import injectSaga from '../../utils/injectSaga'; // _platform
import { userLogoutRequest, userTokenRequest } from './actions';
import {
  selectCurrentUser,
  selectIsFetchingToken,
  selectStatus,
} from './selectors';
import saga from './saga';

class App extends Component {
  componentDidMount() {
    // Set API Authorization token if one is stored
    apiSetAuthorizationToken(this.props.currentUser.token);

    // Refresh user's token if necessary
    this.tokenRefresh(true); // Action this even if the user has a token, even if it's not close to expiry. To ensure that the token is still valid after a refresh

    // Set up a page change listener
    this.props.history.listen(location => {
      // If the current page is not /login/, refresh the token if necessary
      if (location.pathname !== this.props.theme.settingsApp.loginPagePath)
        this.tokenRefresh();
    });
  }

  /**
   * refreshToken
   *
   * Determine if the user's authentication token needs to be refreshed
   * in order to keep them logged in safely for as long as possible
   *
   * @param {force} bool If token is present refresh it, bypassing token expiry check
   */
  tokenRefresh(force) {
    const { currentUser, isFetchingToken } = this.props;

    if (currentUser.token && currentUser.tokenData && !isFetchingToken) {
      const { authTokenRefreshBeforeExpiry } = this.props.theme.settingsApp;

      // Determine the lifespan of a token
      const tokenLifeTime =
        (currentUser.tokenData.tokenExpiry -
          currentUser.tokenData.generatedLocal) /
        60;

      // Check if the authTokenRefreshBeforeExpiry is longer than the max life of token
      // If so, refresh token after 5 mins of token generation
      const refreshPeriod =
        authTokenRefreshBeforeExpiry < tokenLifeTime
          ? authTokenRefreshBeforeExpiry // If the setting is less than token max life
          : tokenLifeTime - 5; // After 5 minutes

      const triggerFetchTime = new Date(
        currentUser.tokenData.tokenExpiry * 1000
      );
      triggerFetchTime.setMinutes(
        triggerFetchTime.getMinutes() - refreshPeriod
      );

      if (force || new Date() > triggerFetchTime) {
        // Check token expiry time? If so, and the token has expired, it may leave
        // the user feeling as though they are logged in when they are not.
        // If we don't check, then the fetch will run, return a 401 and push to logout.
        this.props.dispatch(userTokenRequest(this.props.theme.settingsApp));
      }
    }
  }

  render() {
    if (this.props.children) {
      return React.Children.map(this.props.children, childElement =>
        React.cloneElement(childElement, this.props)
      );
    }

    return null;
  }
}

App.propTypes = {
  children: PropTypes.node.isRequired,
  currentUser: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isFetchingToken: PropTypes.bool.isRequired,
  onLogout: PropTypes.func.isRequired,
  status: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch, props) {
  const settingsApp = (props.theme && props.theme.settingsApp) || {};

  return {
    onLogout: () => dispatch(userLogoutRequest({ settingsApp })),
    dispatch,
  };
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser(),
  isFetchingToken: selectIsFetchingToken(),
  status: selectStatus(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withSaga = injectSaga({ key: 'app', saga });

export default compose(
  withTheme,
  withSaga,
  withRouter,
  withConnect
)(App);
