/**
 * AppReducer
 *
 * This is the global app reducer
 */

import {
  COUNTRIES_REQUEST,
  COUNTRIES_SUCCESS,
  COUNTRIES_ERROR,
  SET_USER,
  USER_LOGOUT_REQUEST,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_ERROR,
  USER_TOKEN_REQUEST,
  USER_TOKEN_SUCCESS,
  USER_TOKEN_ERROR,
} from './constants';

import { retrieveToken } from '../../utils/tokenUtils'; // _platform

export const initialState = {
  countries: undefined,
  currentUser: retrieveToken() || {},
  currentUserTemp: {}, // Used for incomplete profiles
  isFetchingToken: false,
  profileDetails: undefined,
  status: undefined,
  statusDetails: {},
  userOptions: {},
};

function appReducer(state = initialState, action) {
  switch (action.type) {
    case COUNTRIES_REQUEST:
      return {
        ...state,
        countries: {},
        status: 'loading',
        statusDetails: {},
      };
    case COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: {
          data: action.response,
          lastUpdated: action.lastUpdated,
        },
        status: null,
      };
    case COUNTRIES_ERROR:
    case USER_PROFILE_ERROR:
      return {
        ...state,
        status: 'error',
        statusDetails: action.error,
      };
    case SET_USER: {
      if (
        !action.options ||
        (action.options.isProfileValid === true &&
          action.options.forcePasswordChange === false)
      ) {
        return {
          ...state,
          currentUser: action.details,
          currentUserTemp: initialState.currentUserTemp, // Return the temp value to initial state
          status: null,
          statusDetails: {},
          userOptions: {},
        };
      }

      // For incomplete profiles: set the temporary currentUser value to ensure we don't
      // accidentally give full access to the user. Only certain modules should be checking
      // this value - eg, Login and Profile - to display forms/submit profile update info
      return {
        ...state,
        currentUserTemp: action.details,
        status: null,
        statusDetails: {},
        userOptions: action.options || {},
      };
    }
    case USER_LOGOUT_REQUEST:
      // Clear all state, reuturn to initialState and make sure the currentUser is reset
      return {
        ...initialState,
        currentUser: {},
      };
    case USER_PROFILE_REQUEST:
      if (state.profileDetails) {
        // If we already have profile details, refresh. Do not destructure to save a re-render
        return state;
      }

      return {
        ...state,
        profileDetails: {},
        status: 'loading',
        statusDetails: {},
      };
    case USER_PROFILE_SUCCESS:
      return {
        ...state,
        profileDetails: {
          data: action.response,
          lastUpdated: action.lastUpdated,
        },
        status: null,
      };
    case USER_TOKEN_REQUEST: {
      return {
        ...state,
        isFetchingToken: true,
        userOptions: {},
      };
    }
    case USER_TOKEN_SUCCESS:
    case USER_TOKEN_ERROR:
      return {
        ...state,
        isFetchingToken: false,
      };
    default:
      return state;
  }
}

export default appReducer;
