/**
 * Simple Slugify
 *
 * Replacing `@sindresorhus/slugify` with this method as we only need a simple slug solution (we control the slug input)
 * Reason to move away from slugify is that it requires `String#normalize` which is no longer included in `core-js`
 * and the alternative `unorm` is 34KB gzipped
 * https://github.com/zloirock/core-js/blob/16dc3b2adac4e6419d7d69bf6e5020808ec94864/README.md#missing-polyfills
 *
 * Inspiration: https://gist.github.com/mathewbyrne/1280286
 *
 * @param {string} text The string to be slugified
 * @returns {string} Slugified text
 */
export default function simpleSlugify(text) {
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\s-]/g, '') // remove non-word [a-z0-9_], non-whitespace, non-hyphen characters
    .replace(/[\s_-]+/g, '-') // swap any length of whitespace, underscore, hyphen characters with a single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}
