/**
 * Gets the app and user configuration
 */

import { call, put, takeLatest } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import qs from 'qs';
import history from '../../utils/history'; // _platform
import {
  api,
  apiErrorHandler,
  apiSetAuthorizationToken,
} from '../../utils/request'; // _platform
import { formatToken, storeToken, removeToken } from '../../utils/tokenUtils'; // _platform
import { removeProperty } from '../../utils/utilities'; // _platform
import getLoginPath from '../../utils/getLoginPath'; // _platform

import {
  COUNTRIES_REQUEST,
  USER_LOGOUT_REQUEST,
  USER_PROFILE_REQUEST,
  USER_TOKEN_REQUEST,
} from './constants';
import {
  countriesSuccess,
  countriesError,
  setUser,
  userLogoutRequest,
  userProfileSuccess,
  userProfileError,
  userTokenSuccess,
  userTokenError,
} from './actions';

/**
 * Request App Settings
 */
export function* requestCountries() {
  try {
    yield put(showLoading());
    const response = yield call(() =>
      api.get('/Country/v1/true', {
        description: 'Countries', // Description is used as title for any errors (optional)
      })
    );
    yield put(countriesSuccess(response.data, Math.floor(Date.now() / 1000)));
  } catch (err) {
    yield put(countriesError(apiErrorHandler(err)));
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request User Logout
 *
 * 1. Remove currentUser from state (done in reducer)
 * 2. Send logout API request, unless options.options.skipRemote is true
 * 3. Remove token from localStorage and sessionStorage
 * 4. Redirect user to login page (with querystring)
 */
export function* requestUserLogout(options) {
  try {
    if (!options.options || options.options.skipRemote !== true) {
      yield call(() =>
        api.post('/Authentication/v1/Logout', {
          description: 'User logout',
        })
      );
    }

    removeToken();
    apiSetAuthorizationToken(null);
  } catch (err) {
    // errors
  } finally {
    // redirect with Querystring if necessary
    const loginPath = getLoginPath(
      (options.options && options.options.settingsApp) || {} // Blank object if coming from request.js
    );

    if (loginPath) {
      yield call(history.push, loginPath);
    }
  }
}

/**
 * Request User Token (refresh)
 */
export function* requestUserToken(options) {
  try {
    yield put(showLoading());

    // On logout page, don't try to refresh the token - return error in order to log the user out
    if (history.location.pathname === options.settingsApp.logoutPagePath) {
      yield put(userTokenError());
    } else {
      const response = yield call(() =>
        api.post('/Authentication/v1/RefreshToken', {
          settingsApp: {
            loginPagePath:
              options &&
              options.settingsApp &&
              options.settingsApp.loginPagePath,
          },
        })
      );

      // Format user's token data
      const userData = formatToken(response.data);

      // Get the query string
      // Use lowercaseKeys to make the object keys predictable
      const pageQueryString = qs.parse(
        history.location.search.replace(/returnUrl/gi, 'returnUrl'),
        {
          ignoreQueryPrefix: true,
        }
      );

      // Original queryString but sanitised
      const cleanQueryString = qs.stringify(pageQueryString);

      // Remove returnUrl from querystring and keep the remainder
      const trimmedQueryString = qs.stringify(
        removeProperty(pageQueryString, 'returnUrl')
      );

      // Set API Authorization token to be used on subsequent calls
      apiSetAuthorizationToken(userData.token);

      // Check if the user has a valid profile
      if (
        response.data.isProfileValid === true &&
        response.data.forcePasswordChange === false
      ) {
        // Store the authentication info
        storeToken(userData, options.settingsApp.loginUseSessionStorage);

        // Put authentication info into App state
        yield put(setUser(userData));
        yield put(userTokenSuccess()); // After setUser() in order to avoid infinite loops
      } else {
        // Put authentication info into App state as currentUserTemp until the profile is complete
        // yield put(setUser(userData, 'incompleteProfile'));
        yield put(
          setUser(userData, {
            forcePasswordChange: response.data.forcePasswordChange,
            isProfileValid: response.data.isProfileValid,
          })
        );
        yield put(userTokenSuccess()); // After setUser() in order to avoid infinite loops

        const { redirectOnIncompleteProfile } = options.settingsApp;

        // Check if we should redirect to another page if the profile is incomplete
        // Include any query strings that may be present
        if (
          redirectOnIncompleteProfile &&
          response.data.forcePasswordChange === false
        ) {
          // Remove returnUrl if it matches redirectOnIncompleteProfile path
          const redirectQS =
            pageQueryString.returnUrl === redirectOnIncompleteProfile
              ? trimmedQueryString
              : cleanQueryString;

          yield call(history.push, {
            pathname: redirectOnIncompleteProfile,
            search: redirectQS && `?${redirectQS}`,
          });
        }
      }
    }
  } catch (err) {
    yield put(userTokenError(apiErrorHandler(err)));

    yield put(
      userLogoutRequest({
        skipRemote: true,
      })
    );
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request profile
 */
export function* requestUserProfile() {
  try {
    yield put(showLoading());
    const response = yield call(() =>
      api.get('/User/v1/Profile', {
        description: 'Profile details',
      })
    );

    yield put(userProfileSuccess(response.data, Math.floor(Date.now() / 1000)));
  } catch (err) {
    yield put(userProfileError(apiErrorHandler(err)));
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Default saga managers the watcher lifecycle
 */
export default function* appSaga() {
  // Specify the constant to watch and the saga generator function to call when one is triggered
  // It returns a task descriptor (just like a fork) so we can continue execution, and will be
  // automatically cancelled on unmount (unless the mode is set to DAEMON)
  // Specify as many yields (watchers) here as necessary
  yield takeLatest(COUNTRIES_REQUEST, requestCountries);
  yield takeLatest(USER_LOGOUT_REQUEST, requestUserLogout);
  yield takeLatest(USER_PROFILE_REQUEST, requestUserProfile);
  yield takeLatest(USER_TOKEN_REQUEST, requestUserToken);
}
