/**
 * API Requests
 */
import axios from 'axios';
import store from './store'; // _platform

import { userLogoutRequest } from '../containers/App/actions'; // _platform

// Take the API Host value from environment variables
const apiHost = process.env.REACT_APP_API_HOST || '';

// Set axios defaults
axios.defaults.headers.post['Content-Type'] = 'application/json';

// Create a custom axios instance
export const api = axios.create({
  baseURL: `${apiHost}/api`,
  // timeout: 2000,
});

/**
 * Interceptors
 *
 * If the server returns 401, it's too late - cannot refresh the token.
 * Log the user out, and remove the token from storage.
 */

// Response interceptor
api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // userLogoutRequest - skip remote API call to /Logout as the authentication has already failed (status 401)
      const requestData =
        error.response.config.url.indexOf('RefreshToken') !== -1
          ? JSON.parse(error.response.config.data)
          : {};

      store.dispatch(
        userLogoutRequest({
          skipRemote: true,
          settingsApp: requestData.settingsApp,
        })
      );
    }
    return Promise.reject(error);
  }
);

/**
 * Set Authorization Token on the axios instance to be used on every call
 * if the user is logged in.
 *
 * Note: do not use api.defaults.headers.common... as that will impact all instances.
 *
 * @param {string} token User's token
 */
export function apiSetAuthorizationToken(token) {
  if (token) {
    api.defaults.headers['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers['Authorization'];
  }
}

/**
 * apiErrorHandler - Format errors to be stored in state
 *
 * @param {Error} error
 * @returns {object}
 */
export function apiErrorHandler(error) {
  if (error.response) {
    // Server responded with a status code not in 2xx range
    return {
      title: error.config.description,
      message:
        (error.response.data && error.response.data.message) ||
        (error.response.data && error.response.data.title) ||
        error.response.statusText ||
        (typeof error.response.data === 'string' && error.response.data) || // If data is blank, it returns a falsy value
        (error.response.data === '' &&
          `Could not access ${error.config.description}`),
      messageDetails: error.message,
      status: error.response.status,
      // apiHeaders: error.response.headers,
    };
  }

  if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest
    return {
      title: error.config.description,
      message: 'There was a problem retrieving data',
      messageDetails: error.message,
    };
  }

  // All other errors
  return {
    title: error.title || (error.config && error.config.description) || null,
    message: error.message,
  };
}
