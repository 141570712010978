import React from 'react';

// Style and SEO
import { css, up, styled, Grid, Row, Col } from '@smooth-ui/core-sc';
import { Link } from 'react-router-dom';
import { withSettings } from 'containers/WebApp/SettingsContext';
import PrivateComponent from '_platform/src/utils/PrivateComponent';
import logo from './images/BOB-2023-NafdaLogo@2x.png';
import LinkedIn from './images/BOB-2023-LinkedIn-Icon@2x.png';
import Facebook from './images/BOB-2023-Facebook-Icon@2x.png';
import Instagram from './images/BOB-2023-Instagram-Icon@2x.png';

const FooterWrapper = styled.footer`
  background-color: #949598;
  border-top: 8px solid #949598;
`;

const NafdaLogo = styled.img`
  width: 240px;
  padding-top: 20px;

  ${up(
    'md',
    css`
      width: 170px;
    `
  )};

  ${up(
    'lg',
    css`
      width: 240px;
      padding-top: 0;
    `
  )};
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 2rem 0 1rem 0;
  color: #fff;
  ${up(
    'md',
    css`
      flex-direction: row;
      justify-content: space-between;
    `
  )};
  p {
    font-size: 16px;
    text-align: center;

    ${up(
      'md',
      css`
        padding-left: 0.6rem;
        text-align: left;
      `
    )};
  }
  ul {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    ${up(
      'md',
      css`
        flex-direction: row;
      `
    )};
  }
  li {
    font-size: 16px;
    color: #fff;
    text-align: center;
    padding: 2px 0;
    ${up(
      'md',
      css`
        border-right: 1px solid #fff;
        padding: inherit;
        text-align: left;
        &:nth-last-child(1) {
          border-right: none;
        }
      `
    )};
    a {
      color: #fff;
      font-weight: normal;
      text-decoration: none;
      padding: 0.1rem 0.65rem;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const today = new Date();

const Footer = () => (
  <FooterWrapper>
    <FooterContainer>
      <Grid>
        <Row>
          <Col xs={12} md={9} lg={7}>
            <Grid>
              <Row>
                <Col xs={12}>
                  <p>
                    {today.getFullYear()} &copy; NAFDA Foodservice. All rights
                    reserved.
                  </p>
                </Col>
              </Row>
              <Row className="spacer--bottom--small">
                <Col xs={12} className="text--left">
                  <ul>
                    <li>
                      <Link to="/sitemap">Sitemap</Link>
                    </li>
                    <li>
                      <Link to="/privacy">Privacy policy</Link>
                    </li>
                    <li>
                      <Link to="/terms">Terms &amp; conditions</Link>
                    </li>

                    <PrivateComponent>
                      <li>
                        <Link to="/faqs">FAQs</Link>
                      </li>
                    </PrivateComponent>
                    <li>
                      <Link to="/contact">Contact us</Link>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Grid>
          </Col>
          <Col xs={12} md={3} lg={5}>
            <Grid>
              <Row justifyContent="center">
                <Col xs={12} lg={6} className="text--center">
                  <Grid>
                    <Row justifyContent="center">
                      <Col
                        xs={12}
                        className="text--center spacer--bottom--small"
                      >
                        Connect with us
                      </Col>
                    </Row>
                    <Row justifyContent="center">
                      <Col xs={2} md={4} lg={3} className="text--center">
                        <a
                          href="https://www.linkedin.com/company/nafda/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={LinkedIn}
                            alt="LinkedIn"
                            height="33"
                            width="33"
                          />
                        </a>
                      </Col>
                      <Col xs={2} md={4} lg={3} className="text--center">
                        <a
                          href="https://www.facebook.com/bestofthebestrewards/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={Facebook}
                            alt="Facebook"
                            height="33"
                            width="33"
                          />
                        </a>
                      </Col>
                      <Col xs={2} md={4} lg={3} className="text--center">
                        <a
                          href="https://www.instagram.com/bestofthebestrewards/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={Instagram}
                            alt="Instagram"
                            height="33"
                            width="33"
                          />
                        </a>
                      </Col>
                    </Row>
                  </Grid>
                </Col>
                <Col xs={12} lg={6} className="text--center">
                  <a
                    href="https://www.nafda.com.au"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <NafdaLogo src={logo} alt="NAFDA Food Service" />
                  </a>
                </Col>
              </Row>
            </Grid>
          </Col>
        </Row>
      </Grid>
    </FooterContainer>
  </FooterWrapper>
);

export default withSettings(Footer);
