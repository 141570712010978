import React from 'react';
import PropTypes from 'prop-types';

const SettingsContext = React.createContext();

export const SettingsProvider = ({ settings, ...props }) => (
  <SettingsContext.Provider value={settings}>
    {props.children}
  </SettingsContext.Provider>
);

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.object.isRequired,
};

export function withSettings(Component) {
  return function contextComponent(props) {
    return (
      <SettingsContext.Consumer>
        {context => <Component {...props} settings={context} />}
      </SettingsContext.Consumer>
    );
  };
}

export default withSettings;
