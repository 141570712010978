/**
 * App selectors
 */
import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Container state
 */
const selectGlobalState = state => state.global || initialState;

/**
 *  Fetch state
 */
const selectStatus = () =>
  createSelector(
    selectGlobalState,
    state => ({
      status: state.status,
      statusDetails: state.statusDetails,
    })
  );

/**
 * Countries
 */
const selectCountries = () =>
  createSelector(
    selectGlobalState,
    state => state.countries
  );

/**
 * Current user
 */
const selectCurrentUser = () =>
  createSelector(
    selectGlobalState,
    state => state.currentUser
  );

const selectCurrentUserTemp = () =>
  createSelector(
    selectGlobalState,
    state => state.currentUserTemp
  );

const selectIsFetchingToken = () =>
  createSelector(
    selectGlobalState,
    state => state.isFetchingToken
  );

const selectUserOptions = () =>
  createSelector(
    selectGlobalState,
    state => state.userOptions
  );

/**
 * Profile details
 */
const selectUserProfile = () =>
  createSelector(
    selectGlobalState,
    state => state.profileDetails
  );

export {
  selectGlobalState,
  selectCountries,
  selectCurrentUser,
  selectCurrentUserTemp,
  selectIsFetchingToken,
  selectStatus,
  selectUserOptions,
  selectUserProfile,
};
