import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Redirect } from 'react-router-dom';
import {
  selectCurrentUser,
  selectCurrentUserTemp,
} from '../containers/App/selectors'; // _platform
import checkPermissions from '../utils/checkPermissions'; // _platform
import checkRoles from '../utils/checkRoles'; // _platform
import MessageBox from '../components/MessageBox/MessageBox'; // _platform

/**
 * Private Component - HoC to determine whether a component should be displayed
 * based on authentication, permissions and/or roles
 *
 * TODO: Extend with prop to supply function to determine permission `verifyFn` similar to LoadAsync
 *
 * The `user` prop can be either currentUser or currentUserTemp, depending on what the parent component determines is applicable.
 * If the `user` prop is not supplied, then this component will retrieve the user data via the App selectors.
 * The `tempAuthAllowed` prop determines whether currentUserTemp can be used
 *
 * `publicOnly` prop inverts the check so that the child component is only displayed to unauthenticated users (use `PublicComponent`)
 *
 * `deniedPermissions` and `deniedRoles` props take precedence over `requiredPermissions` and `requiredRoles`.
 * For example, if the props are `requiredRoles={['Administrator']} deniedRoles={['Administrator']}`,
 * users with the `Administrator` role will be denied access to the child component.
 *
 * If the checks determine that the access should be denied to the child component:
 *   - By default the child component is skipped silently
 *   - If the `redirectOnError` prop is supplied, the user will be redirected to the supplied local path
 *   - Otherwise, if the `displayError` prop is supplied, the user will be shown an error
 *      which can be customised via the `errorMessage` prop
 */

class PrivateComponent extends Component {
  render() {
    const {
      deniedPermissions,
      deniedRoles,
      displayError,
      errorMessage,
      publicOnly,
      redirectOnError,
      requiredPermissions,
      requiredRoles,
      tempAuthAllowed,
      user,
    } = this.props;
    const { currentUser, currentUserTemp } = this.props;

    const UnauthorisedComponent = displayError ? (
      <MessageBox variant="warning">
        {errorMessage || 'Unauthorised'}
      </MessageBox>
    ) : null;

    const RedirectOnErrorComponent = redirectOnError ? (
      <Redirect push to={redirectOnError} />
    ) : null;

    // Determine which user object to use
    // 1. Use `user` prop if supplied
    // 2. If `tempAuthAllowed` prop:
    //   1. Is true: use `currentUserTemp` if available, if not use currentUser
    // 3. Is false: use currentUser
    //
    // Check for user.token and currentUserTemp.token to ensure they're not the
    // default empty objects. Cleaner than using Object.keys
    let userObject = {};
    if (user && user.token && user.userId) {
      userObject = user;
    } else if (tempAuthAllowed && currentUserTemp && currentUserTemp.token) {
      userObject = currentUserTemp;
    } else {
      userObject = currentUser;
    }

    // Invert the check if the publicOnly prop is supplied - used in PublicComponent
    if (publicOnly) {
      return !userObject.token
        ? this.props.children
        : RedirectOnErrorComponent || UnauthorisedComponent;
    }

    // Check granular permissions if supplied
    if (
      !!userObject.token &&
      ((deniedPermissions && checkPermissions(userObject, deniedPermissions)) ||
        (requiredPermissions &&
          !checkPermissions(userObject, requiredPermissions)))
    ) {
      return RedirectOnErrorComponent || UnauthorisedComponent;
    }

    // Check granular roles if supplied
    if (
      !!userObject.token &&
      ((deniedRoles && checkRoles(userObject, deniedRoles)) ||
        (requiredRoles && !checkRoles(userObject, requiredRoles)))
    ) {
      return RedirectOnErrorComponent || UnauthorisedComponent;
    }

    return !!userObject.token
      ? this.props.children
      : RedirectOnErrorComponent || UnauthorisedComponent;
  }
}

PrivateComponent.propTypes = {
  children: PropTypes.node.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentUserTemp: PropTypes.object.isRequired,
  deniedPermissions: PropTypes.array,
  deniedRoles: PropTypes.array,
  displayError: PropTypes.bool,
  errorMessage: PropTypes.string,
  publicOnly: PropTypes.bool, // Inverts PrivateComponent - displays component to unauthenticated users only. Used by PublicComponent wrapper
  redirectOnError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  requiredPermissions: PropTypes.array,
  requiredRoles: PropTypes.array,
  tempAuthAllowed: PropTypes.bool,
  user: PropTypes.object,
};

PrivateComponent.defaultProps = {
  deniedPermissions: undefined,
  deniedRoles: undefined,
  displayError: false,
  errorMessage: undefined,
  publicOnly: false,
  redirectOnError: undefined,
  requiredPermissions: undefined,
  requiredRoles: undefined,
  tempAuthAllowed: false,
  user: {},
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser(),
  currentUserTemp: selectCurrentUserTemp(),
});

export default connect(
  mapStateToProps,
  null
)(PrivateComponent);
