/**
 * Wrapper for react-toastify
 */
import { toast as toastify } from 'react-toastify';

const toast = (type, message, options = {}) => {
  if (!message) return false;
  const toastType = !type || type === '' ? 'default' : type;
  const toastOptions = {
    ...options,
    type: toastType,
    autoClose: type === 'error' ? 6000 : options.autoClose,
  };

  return toastify(message, toastOptions);
};

export default toast;
