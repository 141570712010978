/**
 * Lori Footer
 */

import React from 'react';
import { styled } from '@smooth-ui/core-sc';

const StyledLoriFooter = styled.div`
  background-color: #141f2d;
  color: #fff;
  flex-shrink: 0;
  font-size: 14px;
  padding: 0.5em;
  text-align: center;
  width: 100%;

  a {
    color: #f4bd19;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const LoriFooter = () => (
  <StyledLoriFooter className="lori-footer" id="lori">
    Powered by Lori from{' '}
    <a
      href="https://www.incremental.com.au/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Incremental
    </a>
  </StyledLoriFooter>
);

export default LoriFooter;
