/**
 * WebApp saga
 */

import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import slugify from '_platform/src/utils/simpleSlugify';
import toast from '_platform/src/utils/toast';
import { api, apiErrorHandler } from '_platform/src/utils/request';
import { flattenDeepArrayOfObjects } from '_platform/src/utils/utilities';
import { USER_LOGOUT_REQUEST } from '_platform/src/containers/App/constants';
import {
  CART_REQUEST,
  CLIENTS_REQUEST,
  CUSTOMER_ACCOUNTS_REQUEST,
  CUSTOMER_REPS_REQUEST,
  MENU_REQUEST,
  SETTINGS_REQUEST,
  WISHLIST_REQUEST,
  WISHLIST_ADDREMOVE_REQUEST,
} from './constants';
import {
  cartSuccess,
  cartError,
  clientsSuccess,
  clientsError,
  customerAccountsSuccess,
  customerAccountsError,
  customerRepsSuccess,
  customerRepsError,
  menuSuccess,
  menuError,
  menuUpdateRoutes,
  menuRequest,
  settingsSuccess,
  settingsError,
  userLogoutClearUserData,
  wishlistRequest,
  wishlistSuccess,
  wishlistError,
} from './actions';

/**
 * Request Cart
 */
export function* requestCart() {
  try {
    yield put(showLoading());

    const response = yield call(() =>
      api.get('/Cart/v1', {
        description: 'Cart',
      })
    );

    yield put(
      cartSuccess(
        (response.data &&
          response.data.cartRewards &&
          response.data.cartRewards.length > 0 && {
            ...response.data,
            cartRewards: response.data.cartRewards.map(reward => ({
              ...reward,
              // Must match RewardsProvider `requestRewards` saga
              slug: slugify(`${reward.rewardCode}-${reward.rewardId}`),
            })),
          }) ||
          response.data
      )
    );
  } catch (err) {
    const error = apiErrorHandler(err);

    if (error.status === 401) {
      yield put(cartError(error));
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request clients
 */
export function* requestClients() {
  try {
    yield put(showLoading());
    const response = yield call(() =>
      api.get('/Client/v1', {
        description: 'Clients',
      })
    );

    yield put(clientsSuccess(response.data, Math.floor(Date.now() / 1000)));
  } catch (err) {
    const error = apiErrorHandler(err);
    const errorMsg = error.message || 'Could not retrieve clients';

    if (error.status === 401) {
      // Reset the definitions back to undefined in order to re-fetch after login
      yield put(clientsError(error, true));
    } else {
      yield call(toast, 'error', errorMsg);
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request Customer Accounts
 */
export function* requestCustomerAccounts() {
  try {
    yield put(showLoading());

    const response = yield call(() =>
      api.get(`/Customer/v1/Accounts`, {
        description: 'Customer accounts',
      })
    );

    yield put(
      customerAccountsSuccess(response.data, Math.floor(Date.now() / 1000))
    );
  } catch (err) {
    const error = apiErrorHandler(err);

    if (error.status === 401) {
      // Reset the buying periods back to undefined in order to re-fetch after login
      yield put(customerAccountsError(error));
    } else {
      yield call(toast, 'error', error.message);
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request Customer Reps
 */
export function* requestCustomerReps() {
  try {
    yield put(showLoading());

    const response = yield call(() =>
      api.get('/Customer/v1/Representatives', {
        description: 'Customer Representatives',
      })
    );

    yield put(
      customerRepsSuccess(response.data, Math.floor(Date.now() / 1000))
    );
  } catch (err) {
    const error = apiErrorHandler(err);

    if (error.status === 401) {
      yield put(customerRepsError(error));
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request Menu
 */
export function* requestMenu() {
  try {
    yield put(showLoading());

    const response = yield call(() =>
      api.get('/Menu/v1', {
        description: 'Menu',
      })
    );

    yield put(menuSuccess(response.data));

    // Routes
    const routes =
      response.data && response.data.length > 0
        ? flattenDeepArrayOfObjects(response.data, 'children')
        : null;

    yield put(menuUpdateRoutes(routes));

    if (routes) {
      localStorage.setItem('routes', JSON.stringify(routes));
    } else {
      localStorage.removeItem('routes');
    }
  } catch (err) {
    const error = apiErrorHandler(err);

    if (error.status === 401) {
      yield put(menuError(error));
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request Settings
 */
export function* requestSettings() {
  try {
    yield put(showLoading());

    const [claimingPeriods, program, unitsOfMeasure] = yield all([
      call(() =>
        api.get('/ClaimingPeriod/v1', {
          description: 'Claiming Period',
        })
      ),
      call(() =>
        api.get('/Program/v1', {
          description: 'Program Settings',
        })
      ),
      call(() =>
        api.get('/UnitOfMeasure/v1', {
          description: 'Units of measure',
        })
      ),
    ]);

    yield put(
      settingsSuccess(
        {
          claimingPeriods: claimingPeriods.data.map(item => ({
            ...item,
            dateStartsUser: new Date(item.dateStartsUtc),
            dateEndsUser: new Date(item.dateEndsUtc),
          })),
          program: program.data,
          unitsOfMeasure: unitsOfMeasure.data,
        },
        Math.floor(Date.now() / 1000)
      )
    );
  } catch (err) {
    const error = apiErrorHandler(err);

    if (error.status === 401) {
      yield put(settingsError(error));
    } else {
      yield call(
        toast,
        'error',
        'There was a problem retrieving site settings'
      );
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request Wishlist
 */
export function* requestWishlist() {
  try {
    yield put(showLoading());

    const response = yield call(() =>
      api.get('/Wishlist/v1', {
        description: 'Wishlist',
      })
    );

    yield put(
      wishlistSuccess(
        response.data && response.data.length > 0
          ? response.data.map(reward => ({
              ...reward,
              // Must match RewardsProvider `requestRewards` saga
              slug: slugify(`${reward.rewardCode}-${reward.rewardId}`),
            }))
          : response.data
      )
    );
  } catch (err) {
    const error = apiErrorHandler(err);

    if (error.status === 401) {
      yield put(wishlistError(error));
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request wishlist add/remove
 *
 * @param {object} values The action (add/remove) and reward properties
 */
export function* requestWishlistAddRemove(values) {
  const payload = values.values;

  try {
    yield put(showLoading());

    // eslint-disable-next-line no-unused-vars
    const response =
      values.action.toLowerCase() === 'add'
        ? yield call(() =>
            api.post('/Wishlist/v1/Add', payload, {
              description: 'Add reward to wishlist',
            })
          )
        : yield call(() =>
            api.delete(
              `/Wishlist/v1/Remove/${values.values.wishlistId}`,
              undefined,
              { description: 'Remove reward from wishlist' }
            )
          );

    // Refresh the wishlist
    yield put(wishlistRequest());
  } catch (err) {
    const action =
      values.action.toLowerCase() === 'add' ? 'Adding' : 'Removing';

    yield call(
      toast,
      'error',
      `There was a problem ${action.toLowerCase()} the reward ${
        action === 'Adding' ? 'to' : 'from'
      } wishlist`
    );

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.warn(
        `Dev: ${action} reward to wishlist '${apiErrorHandler(err).message}'`
      );
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * User Logout Listener
 */
export function* userLogoutListener() {
  try {
    yield localStorage.removeItem('routes');

    yield delay(200);
    yield put(userLogoutClearUserData());
    yield put(menuRequest(true));
  } catch (err) {
    // console.warn('Error:', err);
  }
}

/**
 * Default saga managers the watcher lifecycle
 */
export default function* rootSaga() {
  // Specify the constant to watch and the saga generator function to call when one is triggered
  // It returns a task descriptor (just like a fork) so we can continue execution, and will be
  // automatically cancelled on unmount (unless the mode is set to DAEMON)
  // Specify as many yields (watchers) here as necessary
  yield takeLatest(CART_REQUEST, requestCart);
  yield takeLatest(CLIENTS_REQUEST, requestClients);
  yield takeLatest(CUSTOMER_ACCOUNTS_REQUEST, requestCustomerAccounts);
  yield takeLatest(CUSTOMER_REPS_REQUEST, requestCustomerReps);
  yield takeLatest(MENU_REQUEST, requestMenu);
  yield takeLatest(SETTINGS_REQUEST, requestSettings);
  yield takeLatest(WISHLIST_REQUEST, requestWishlist);
  yield takeLatest(WISHLIST_ADDREMOVE_REQUEST, requestWishlistAddRemove);
  yield takeLatest(USER_LOGOUT_REQUEST, userLogoutListener);
}
