/**
 * Check Roles - Check if the user has ANY of the required roles
 *
 * @param {object} currentUser The user object containing the roles property
 * @param {array} requiredRoles The array of required roles
 *
 */

import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';

const checkRoles = (currentUser, requiredRoles) => {
  if (
    !requiredRoles ||
    !Array.isArray(requiredRoles) ||
    !currentUser ||
    !currentUser.roles ||
    !Array.isArray(currentUser.roles)
  )
    return false;

  return currentUser.roles.some(item => requiredRoles.indexOf(item) >= 0);
};

checkRoles.propTypes = {
  currentUser: PropTypes.object.isRequired,
  requiredRoles: PropTypes.array.isRequired,
};

const checkRolesMemoized = memoizeOne(checkRoles);

export default checkRolesMemoized;
