import { css, thd } from '@smooth-ui/core-sc';

const globalStyles = css`
  * {
    box-sizing: border-box;
  }

  html {
    height: 100%;
    overflow-x: hidden;
  }

  body {
    color: ${thd('defaultFontColor', '#333')};
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    height: 100%;
    line-height: 1.25;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    min-width: 100%;
    overflow-x: hidden;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  img {
    max-width: 100%;
  }

  hr {
    border: 0;
    border-top: 1px solid ${thd('gray300', '#dee2e6')};
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }

  [disabled] {
    background-color: #ededed;
  }

  ::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.54;
  }

  :focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(51, 102, 153, 0.25) inset; /* #369 */
  }

  /* react-day-picker resets */
  .DayPickerInput.DayPickerInput {
    display: block;
  }

  /* video embeds */
  .embed-responsive {
    display: block;
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
    width: 100%;

    > iframe {
      border: 0;
      bottom: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
`;

export default globalStyles;
