import qs from 'qs';
import history from './history'; // _platform

/**
 * getLoginPath
 *
 * Generate the path and query string (returnUrl) for the login page, in order
 * to return the user to the page from which they came.
 *
 * Important: Always return an object, as quick re-renders cause string-based
 * history.push calls to have the entire path stored in history.location.pathname
 * which leaves history.location.search blank.
 *
 * @returns object (https://reacttraining.com/react-router/web/api/Redirect/to-object)
 */
export default function getLoginPath(settingsApp) {
  const { pathname, search } = history.location;
  const { loginPagePath, logoutPagePath } = settingsApp;

  const queryString = qs.parse(search.replace(/returnUrl/gi, 'returnUrl'), {
    ignoreQueryPrefix: true,
  });

  // If settingsApp values are unavailable and the returnUrl value already exists, keep it
  if (!loginPagePath && !logoutPagePath && queryString.returnUrl) {
    return undefined;
  }

  if (
    pathname === '/' ||
    pathname === loginPagePath ||
    pathname === logoutPagePath ||
    pathname === `${logoutPagePath}/`
  ) {
    // Check if qs exists, pass it along.
    const returnQueryString =
      search !== '' ? `?${qs.stringify(queryString)}` : '';

    return { pathname: loginPagePath, search: returnQueryString };
  }

  if (
    search === '' ||
    (queryString.returnUrl &&
      queryString.returnUrl !== pathname &&
      Object.keys(queryString).length === 1)
  ) {
    // No query string or the path is not loginPagePath
    const returnQueryString = qs.stringify({ returnUrl: pathname });

    return { pathname: loginPagePath, search: `?${returnQueryString}` };
  }

  // search is not empty, update it with the returnUrl
  const queryStringUpdated = qs.stringify({
    ...queryString,
    returnUrl: pathname,
  });

  return {
    pathname: loginPagePath,
    search: `?${queryStringUpdated}`,
  };
}
