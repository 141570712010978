/**
 * Check if the URL is absolute or relative
 *
 * @param {string} url URL path
 */
export function isUrlAbsolute(url) {
  const r = new RegExp('^(?:[a-z]+:)?//', 'i');
  return r.test(url);
}

/**
 * removeProperty - Immutably removes a property from an object
 * Use as an immutable replacement to the `delete` keyword
 *
 * @param {object} obj Object from which the property should be removed
 * @param {string} property Name of the property
 */
export const removeProperty = (obj, property) =>
  Object.keys(obj).reduce((acc, key) => {
    return key !== property ? { ...acc, [key]: obj[key] } : acc;
  }, {});

/**
 * flattenDeepArrayOfObjects - Flatten a deep array of objects
 *
 * Example:
 * const routesOriginal = [
 *   {
 *     route: '/users',
 *     component: 'UsersPage/UsersPage',
 *     subRoutes: [
 *       { route: '/users/:id/profile', component: 'UsersProfile/UsersProfile' },
 *     ],
 *   }
 * ]
 * const routes = flattenDeepArrayOfObjects(routesOriginal, 'subRoutes');
 *
 * Result:
 * [
 *   {
 *     route: '/users',
 *     component: 'UsersPage/UsersPage',
 *   },
 *   {
 *     route: '/users/:id/profile',
 *     component: 'UsersProfile/UsersProfile',
 *   },
 * ]
 *
 * @param {array} arr The array of objects
 * @param {string} childrenProp The property that contains the children
 */
export const flattenDeepArrayOfObjects = function(
  arr,
  childrenProp = 'children'
) {
  const array = Array.isArray(arr) ? arr : [arr];
  return array.reduce(function(acc, item) {
    acc.push(item);
    if (item[childrenProp]) {
      acc = acc.concat(flattenDeepArrayOfObjects(item[childrenProp]));
      // delete item[childrenProp];
    }
    return acc;
  }, []);
};

/**
 * untrailingSlashIt - Remove trailing slash from a path if there is one
 *
 * @param {string} str The URL or path
 */
export const untrailingSlashIt = str => str.replace(/\/$/, '');

/**
 * trailingSlashIt - Add a trailing slash to a path if it doesn't have one
 *
 * @param {string} str The URL or path
 */
export const trailingSlashIt = str => `${untrailingSlashIt(str)}/`;

/**
 * lowercaseFirstLetter - Convert the first character in a string to lower case
 * Used in normalising endpoint response data objects
 *
 * @param {string} string The string
 */
export const lowercaseFirstLetter = string =>
  string.charAt(0).toLowerCase() + string.slice(1);
