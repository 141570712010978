/**
 * Combine all reducers and export the combined reducers
 */

import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';
import globalReducer from '../../containers/App/reducer'; // _platform

export default function createReducer(injectedReducers) {
  return combineReducers({
    // router: routeReducer,
    loadingBar: loadingBarReducer,
    global: globalReducer,
    ...injectedReducers,
  });
}
