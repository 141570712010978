/**
 * Check Permissions - Check if the user has ANY of the required permissions
 *
 * @param {object} currentUser The user object containing the permissions property
 * @param {array} requiredPermissions The array of required permissions
 *
 */

import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';

const checkPermissions = (currentUser, requiredPermissions) => {
  if (
    !requiredPermissions ||
    !Array.isArray(requiredPermissions) ||
    !currentUser ||
    !currentUser.permissions ||
    !Array.isArray(currentUser.permissions)
  )
    return false;

  return currentUser.permissions.some(
    item => requiredPermissions.indexOf(item) >= 0
  );
};

checkPermissions.propTypes = {
  currentUser: PropTypes.object.isRequired,
  requiredPermissions: PropTypes.array.isRequired,
};

const checkPermissionsMemoized = memoizeOne(checkPermissions);

export default checkPermissionsMemoized;
