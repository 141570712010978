/**
 * Redux action types
 *
 * Prefix with the Boilerplate/Project and component names to avoid collisions.
 * Eg:
 * export const COUNTRIES_REQUEST = 'platform/App/COUNTRIES_REQUEST';
 */

export const COUNTRIES_REQUEST = 'platform/App/COUNTRIES_REQUEST';
export const COUNTRIES_SUCCESS = 'platform/App/COUNTRIES_SUCCESS';
export const COUNTRIES_ERROR = 'platform/App/COUNTRIES_ERROR';
export const SET_USER = 'platform/App/SET_USER';
export const USER_LOGOUT_REQUEST = 'platform/App/USER_LOGOUT_REQUEST';
export const USER_PROFILE_REQUEST = 'platform/App/USER_PROFILE_REQUEST';
export const USER_PROFILE_SUCCESS = 'platform/App/USER_PROFILE_SUCCESS';
export const USER_PROFILE_ERROR = 'platform/App/USER_PROFILE_ERROR';
export const USER_TOKEN_REQUEST = 'platform/App/USER_TOKEN_REQUEST';
export const USER_TOKEN_SUCCESS = 'platform/App/USER_TOKEN_SUCCESS';
export const USER_TOKEN_ERROR = 'platform/App/USER_TOKEN_ERROR';
