import React from 'react';
import PropTypes from 'prop-types';
import LoadingAnimation from './LoadingAnimation';

const Loading = props => {
  if (props.error) {
    if (process.env.NODE_ENV === 'development' && props.error.message) {
      console.warn(props.error.message);
    }
    return (
      <div>
        <p>
          <strong>Error</strong>
          {props.error.code === 'MODULE_NOT_FOUND' && (
            <span> Module not found!</span>
          )}
        </p>

        {process.env.NODE_ENV === 'development' && (
          <div>
            <button onClick={props.retry}>Retry</button>
          </div>
        )}
      </div>
    );
  }
  if (props.timedOut) {
    return (
      <div>
        Taking too long to load... <button onClick={props.retry}>Retry</button>
      </div>
    );
  }
  if (props.pastDelay) {
    return <LoadingAnimation />;
  }
  return null;
};

Loading.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  timedOut: PropTypes.bool,
  pastDelay: PropTypes.bool,
  retry: PropTypes.func,
};

Loading.defaultProps = {
  error: false,
  timedOut: false,
  pastDelay: false,
  retry: null,
};

export default Loading;
