// Polyfills required for IE
import 'react-app-polyfill/ie11'; // CRA package for IE 11 support
// https://github.com/facebook/create-react-app/tree/master/packages/react-app-polyfill
import 'core-js/features/array/includes';
import 'core-js/features/reflect/apply';
import 'core-js/features/reflect/construct';
import 'core-js/features/reflect/has';
// Required by us
import 'core-js/features/array/find';
import 'core-js/features/array/virtual/find-index'; // styled-components
import 'core-js/features/number';
import 'core-js/features/object/values'; // Statements/chartjs
// End Polyfills

import React from 'react';
import { hydrate, render } from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import history from '_platform/src/utils/history';
import store from '_platform/src/utils/store';
import 'theme/globalStylesApp';
import { ThemeProvider } from '@smooth-ui/core-sc';
import themeApp from 'theme/themeApp'; // Settings and Theme
import App from '_platform/src/containers/App/App';
import WebApp from 'containers/WebApp/WebApp';
import * as serviceWorker from './serviceWorker';

const rootElement =
  document.getElementById('root') || document.createElement('div');

if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <ThemeProvider theme={themeApp}>
        <Router history={history}>
          <App>
            <WebApp />
          </App>
        </Router>
      </ThemeProvider>
    </Provider>,
    rootElement
  );
} else {
  render(
    <Provider store={store}>
      <ThemeProvider theme={themeApp}>
        <Router history={history}>
          <App>
            <WebApp />
          </App>
        </Router>
      </ThemeProvider>
    </Provider>,
    rootElement
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
